<template>
    <div id="allmap" style="width: 1200px; height: 600px; margin: 0 auto" ref="allmapRef"></div>
</template>

<script>
    export default {
        data() {
            return {
                map: null,
                point:null,
                address:'江苏省南京市江宁区金源路绿地之窗G1栋1603-1605'
            }
        },
        mounted() {
            this.initMap()
            this.addMarker()
        },
        methods: {
            initMap() {
                const container = this.$refs.allmapRef
                this.map = new BMapGL.Map(container)
                this.point = new BMapGL.Point(118.807773, 31.96909)
                this.map.centerAndZoom(this.point, 19)
                this.map.enableScrollWheelZoom(true)
            },
            addMarker() {
                // 创建标记
                const marker = new BMapGL.Marker(this.point)
                this.map.addOverlay(marker)

                // 创建信息窗口内容
                const infoContent = `
                        <div style="margin:0 10px;">
                        <h4 style="margin:0 0 5px 0;">地址详情</h4>
                        <p style="margin:0;">南京苏欧机电设备有限公司</p>
                        <p style="margin:0;">${this.address}</p>
                        </div>
                    `

                // 创建信息窗口
                const infoWindow = new BMapGL.InfoWindow(infoContent, {
                    width: 300, // 信息窗口宽度
                    height: 120 // 信息窗口高度
                })

                // 点击标记显示信息窗口
                marker.addEventListener("click", () => {
                    marker.openInfoWindow(infoWindow)
                })

                // 立即打开信息窗口
                marker.openInfoWindow(infoWindow)
            }
        }
    }
</script>
