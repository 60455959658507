<template>
    <div class="notice-wrap" :class="[isMobile ? 'mobile' : 'pc']">
        <!-- <div class="index-wrap" style="width: 1200px;margin: 0 auto;">
			<el-carousel height="400px" v-loading="loadingAd">
				<el-carousel-item v-for="item in adList" :key="item.adv_id"><el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" /></el-carousel-item>
			</el-carousel>
		</div> -->
        <el-breadcrumb separator="/" class="path">
            <el-breadcrumb-item :to="{ path: '/' }" class="path-home">
                <i class="n el-icon-s-home"></i>
                {{ $lang("common.home") }}
            </el-breadcrumb-item>
            <el-breadcrumb-item class="path-help">{{ $lang("title") }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="width: 1200px; margin: 0 auto;">
            <el-image :src="require('@/assets/images/tiktok.png')" fit="contain"> </el-image>
            <el-image :src="require('@/assets/images/whatsApp.png')" fit="contain"> </el-image>
        </div>
        <div class="notice-detil" v-loading="loading">
            <div class="title">{{ $lang("common.company_name") }}</div>
            <div class="title">{{ $lang("common.tel") }}：+86 02558808446</div>
            <div class="title">{{ $lang("common.email") }}：info@soppv.com</div>
            <div class="title">{{ $lang("common.footer_address") }}</div>
            <!-- <div class="content" v-html="info.about"></div> -->
        </div>
        <!-- <div class="elRofm" style="">
                <el-form :model="formData" :rules="messageRules" ref="ruleForm">
                    <el-form-item prop="name">
                        <el-input v-model="formData.name" :placeholder="$lang('name')">
                            <template slot="prepend">
                                <i class="iconfont iconzhanghao"></i>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="phone">
                        <el-input v-model="formData.phone" :placeholder="$lang('phone')">
                            <template slot="prepend">
                                <i class="iconfont iconshouji-copy"></i>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="email">
                        <el-input v-model="formData.email" :placeholder="$lang('email')">
                            <template slot="prepend">
                                <i class="iconfont iconyouxiang"></i>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="message">
                        <el-input type="textarea" v-model="formData.message" :placeholder="$lang('message')"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" class="rule-button" @click="messageSub('ruleForm')"> {{ $lang("common.submit") }}</el-button>
                    </el-form-item>
                </el-form>
        </div> -->
        <mapPage style="margin-top: 10px;"></mapPage>
    </div>
</template>

<script>
    import { aboutUs, aboutUsMessage } from "@/api/cms/notice"
    import { adList } from "@/api/website"
    import { mapGetters, mapActions } from "vuex"
    import mapPage from './map/index.vue'
    export default {
        name: "about_us",
        components: {mapPage},
        data: () => {
            var isMobile = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error("Mobile phone number cannot be empty"))
                } else {
                    const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/

                    if (reg.test(value)) {
                        callback()
                    } else {
                        callback(new Error("please enter a valid phone number"))
                    }
                }
            }
            return {
                adList: [],
                loadingAd: true,
                info: {},
                loading: true,
                formData: {
                    name: "",
                    phone: "",
                    email: "",
                    message: ""
                }, // 表单数据
                messageRules: {
                    name: [
                        {
                            required: true,
                            name: "Please type in your name",
                            trigger: "blur"
                        }
                    ],
                    phone: [
                        {
                            required: true,
                            validator: isMobile,
                            trigger: "blur"
                        }
                    ],
                    email: [
                        {
                            required: true,
                            email: "Please type in your email",
                            trigger: "blur"
                        }
                    ],
                    message: [
                        {
                            required: true,
                            message: "Please enter a message",
                            trigger: "blur"
                        }
                    ]
                }
            }
        },
        computed: {
            ...mapGetters("device", ["isMobile"]) // 获取 isMobile 状态
        },
        created() {
            this.getAdList()
            this.getDetail()
        },
        methods: {
            messageSub(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        var data = {
                            name: this.formData.name,
                            phone: this.formData.phone,
                            email: this.formData.email,
                            message: this.formData.message
                        }

                        aboutUsMessage(data)
                            .then((res) => {
                                if (res.code >= 0) {
                                    this.$message({
                                        message: "Message successfully！",
                                        type: "success"
                                    })
                                    setTimeout(() => {
                                        location.reload()
                                    }, 2000)
                                } else {
                                    this.$message({
                                        message: res.message,
                                        type: "warning"
                                    })
                                }
                            })
                            .catch((err) => {
                                this.$message.error(err.message)
                            })
                    } else {
                        return false
                    }
                })
            },
            getAdList() {
                adList({ keyword: "NS_PC_CONTACT_US" })
                    .then((res) => {
                        this.adList = res.data.adv_list
                        for (let i = 0; i < this.adList.length; i++) {
                            if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
                        }
                        this.loadingAd = false
                    })
                    .catch((err) => {
                        this.loadingAd = false
                    })
            },
            getDetail() {
                aboutUs()
                    .then((res) => {
                        if (res.data) {
                            this.info = res.data
                            this.loading = false
                        } else {
                            this.$router.push({ path: "/" })
                        }
                    })
                    .catch((err) => {
                        this.loading = false
                        this.$message.error(err.message)
                    })
            }
        }
    }
</script>
<style lang="scss" scoped>
    .pc.notice-wrap {
        .path {
            width: 1200px;
        }
        .notice-detil {
            width: 1200px;
            border: 2px dashed #e9e9e9;
            box-sizing: border-box;
        }
        .elRofm {
            width: 500px;
        }
    }
    .mobile.notice-wrap {
        .elRofm {
            margin: 25px auto 0;
        }
    }
    .elRofm {
        margin: 50px auto 0;
        height: 350px;
        padding: 0 15px;
    }
    .notice-detil {
        background-color: #ffffff;
        min-height: 200px;
        padding: 10px;
        margin: 0 auto;
        .title {
            text-align: left;
            font-size: 18px;
            margin: 10px 0;
        }
        .time {
            text-align: center;
            color: #838383;
            margin-bottom: 17px;
        }

        .notice-info {
            margin: 0 43px;
            border-bottom: 1px dotted #e9e9e9;
        }
        .content {
            padding-top: 10px;
            margin: 0 65px;
        }
    }
    .path {
        padding: 15px 0;
        margin: 0 auto;
    }
</style>
